@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.root {
  max-width: 100vw;
}

.carousel {
  width: toRem(343);
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: toRem(26) 0 0;
  justify-content: center;
  gap: toRem(5);
}

.bullet {
  width: toRem(21);
  height: toRem(2);
  opacity: 0.4;
  background-color: $grey900;

  &Active {
    opacity: 1;
  }
}

.sliderWrapper {
  box-shadow: 0 toRem(20) toRem(40) 0 rgba(0, 0, 0, 0.15);
  border-radius: toRem(26);
  background-color: $white;
}

.slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: toRem(30) 0 toRem(20) 0;

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    min-width: 50%;

    & + & {
      &::after {
        content: '';
        position: absolute;
        top: toRem(20);
        left: 0;
        width: 1px;
        height: toRem(39);
        background-color: $grey300;
      }
    }

    &Title {
      font-size: toRem(49);
      line-height: toRem(49);

      &Wrapper {
        display: flex;
        align-items: center;
        height: toRem(74);
      }

      & span {
        line-height: inherit;
        color: $blue500;
      }
    }

    &Description {
      font-size: toRem(16);
    }
  }
}
