@import '@/styles/functions.module.scss';
@import '@/styles/palette.module.scss';

.wrapper {
  padding-top: toRem(27);
  color: $black;
}

.headline {
  margin-bottom: toRem(84);
}

.catalog {
  margin-bottom: toRem(146);
}

@include respond(mobile) {
  .wrapper {
    padding-top: toRem(0);
  }

  .headline {
    margin-bottom: toRem(30);
  }

  .catalog {
    margin-bottom: toRem(30);
  }
}
