@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  &,
  & * {
    // border: 1px solid red;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: toRem(50);

  padding: toRem(50) 0 toRem(40) 0;
  background-color: $grey50;
}

.title {
  max-width: toRem(820);
  text-align: center;
  font-weight: 900;
  color: $grey900;
}

.mobileOnly {
  display: none;
}

@include respond(mobile) {
  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: flex;
  }

  .title {
    font-size: toRem(27);
    color: $grey900;
  }

  .section {
    padding: toRem(40) toRem(20) 0 toRem(20);
    background-color: $grey50;
    gap: toRem(30);
  }

  .stats {
    display: flex;
    flex-direction: column;
    gap: toRem(20);
    width: 100%;
    background-color: $white;
    border: 1px solid $grey900;
    border-radius: toRem(28);
    padding: toRem(20) toRem(15);

    &Title {
      font-size: toRem(27);
      font-weight: bold;
      color: $grey900;
    }
  }
}
