@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  width: 100%;
  padding: toRem(130) 0 toRem(70) 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 900;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: row;
  margin-top: toRem(56);
}

.mobileOnly {
  display: none;
}

@include respond(mobile) {
  .section {
    padding: toRem(93) 0 toRem(60) 0;
  }

  .title {
    font-size: toRem(27);
    padding: 0 toRem(40);
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: flex;
  }
}
