@import '@/styles/functions.module.scss';
@import '@/styles/palette.module.scss';

.body {
  padding: 0 toRem(20);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: toRem(30);
  grid-row-gap: toRem(30);
}

@include respond(mobile) {
  .body {
    padding: 0;
  }

  .grid {
    display: flex;
    flex-direction: column;
    gap: toRem(30);
  }
}
