@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.root {
  max-width: 100vw;
  width: 100%;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: toRem(40) 0 0;
  justify-content: center;
  gap: toRem(5);
}

.bullet {
  width: toRem(21);
  height: toRem(2);
  background-color: $grey400;

  &Active {
    background-color: $grey900;
  }
}
