@import '@/styles/functions.module.scss';
@import '@/styles/palette.module.scss';

.card {
  border-radius: toRem(16);
  box-shadow: 0 toRem(20) toRem(40) 0 rgba(214, 221, 237, 0.8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: $black;
  &:hover {
    .button {
      text-decoration: none;
    }
  }
}

.image {
  width: 100%;
  height: toRem(192);
  padding: 0 toRem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-height: toRem(170);
  flex: 1;
  padding: toRem(15);
}

.title {
  font-size: toRem(17);
  font-weight: 900;
  line-height: 1.18;
  margin-bottom: toRem(8);
  br {
    display: none;
  }
}

.description {
  font-size: toRem(13);
  line-height: 1.31;
}

.pwMention {
  color: $orange500;
}

.link {
  padding-top: 10px;
  width: fit-content;
  padding: 0;
  margin-top: 10px;
  color: $blue500;
  text-decoration: underline;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}
