@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  margin-top: 5.625rem;
}

.title {
  text-align: center;
  text-transform: uppercase;
  color: $grey500;
  font-size: 1rem;
  font-weight: 500;
}

.carouselWrapper {
  max-width: 750px;
  margin: 35px auto 0;

  :global(.carouselDesktop) {
    @include respond(mobile) {
      display: none;
    }
  }

  :global(.carouselMobile) {
    display: none;

    @include respond(mobile) {
      display: block;
    }
  }

  :global(.swiper-wrapper) {
    align-items: center;
  }
}

.sliderItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 30px;

  div {
    background-color: #c4c4c4;
    height: 2px;
    width: 21px;
    cursor: pointer;
    transition: all 300ms ease;

    &.active {
      background-color: #050038;
    }

    &.semiactive {
      background-color: #82809b;
    }
  }
}

.customersDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: toRem(50);
}
