@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: toRem(30);
  padding: toRem(55) 0;
  border: 1px solid $black;
  max-width: calc(100% - 30px);

  &Block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 toRem(30);
    position: relative;

    & + & {
      &::after {
        content: '';
        position: absolute;
        top: toRem(28);
        left: 0;
        width: 1px;
        height: toRem(50);
        background-color: $grey300;
      }
    }

    &Title {
      font-size: toRem(69);
      line-height: toRem(69);
      width: toRem(170);
      text-align: center;

      &Wrapper {
        display: flex;
        align-items: center;
        height: toRem(104);
      }

      & span {
        line-height: inherit;
        font-weight: 900;
      }
    }

    &Description {
      font-size: toRem(21);
    }
  }
}
