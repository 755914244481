@import '../../../../../styles/functions.module.scss';

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: toRem(39);
  height: roRem(271);
  background-color: #faf3ec;
  position: relative;
  overflow: hidden;
  height: 270px;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.title {
  font-size: toRem(72);
  font-weight: bold;
  margin: 0;
  position: relative;
  z-index: 1;
}

.subtitle {
  font-size: toRem(28);
  font-weight: bold;
  margin: 0;
  margin-top: toRem(5);
  position: relative;
  z-index: 1;
}

@include respond(mobile) {
  .body {
    height: auto;
    padding: toRem(34) toRem(10);
    border-radius: 0;
  }

  .title {
    font-size: toRem(36);
  }

  .subtitle {
    font-size: toRem(20);
  }

  .container {
    padding: 0;
  }
}
