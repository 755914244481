@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: toRem(75);

  padding-top: toRem(50);
  padding-bottom: toRem(92);
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: toRem(47);
}

.topTitle {
  color: $orange500;
}

.title {
  font-size: toRem(34);
  white-space: pre-wrap;
  font-weight: 900;
}

.image {
  order: 1;
  width: toRem(630);

  & img {
    width: 100%;
    height: auto;
  }
}

.content {
  order: 2;
  display: flex;
  flex-direction: column;
  gap: toRem(20);
  position: relative;

  &Title {
    font-size: toRem(34);
    font-weight: bold;
  }

  &Step {
    font-size: toRem(18);
    color: $grey500;
    position: relative;
    max-width: toRem(505);

    & span {
      font-weight: bold;
      margin-right: toRem(5);
      position: relative;
      z-index: 1;
    }

    img {
      position: absolute;
      top: toRem(20);
      left: -10px;
    }
  }
}

@include respond(mobile) {
  .section {
    padding-top: toRem(72);
    gap: toRem(18);
  }

  .title {
    font-size: toRem(27);
    padding: 0 toRem(20);
  }

  .container {
    grid-template-columns: 1fr;
    gap: toRem(5);
    display: grid;
    padding-left: toRem(20);
    padding-right: toRem(20);
  }

  .content {
    order: 1;

    &Title,
    &Step {
      font-size: toRem(17);
    }
  }

  .image {
    order: 2;
    width: 100%;
  }
}
