@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.wrapper {
  max-width: 1100px;
  width: 100%;
  height: 618px;
  position: relative;
  margin: 0 auto;
  box-shadow: 0 1.25rem 2.5rem 0 rgba(214, 221, 237, 0.8);
  border-radius: 18px;
  margin-top: 10px;

  @include respond(mobile) {
    height: 365px;
  }

  video {
    object-fit: cover;
    object-position: top;
    border-radius: 18px;
  }
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
  }

  &:active {
    transform: translate(-50%, -50%) scale(1.02);
  }

  img {
    vertical-align: middle;
  }
}
