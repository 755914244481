@import '@/styles/functions.module.scss';
@import '@/styles/palette.module.scss';

.section {
  padding-top: toRem(70);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  max-width: toRem(980);
  font-weight: 900;
  text-align: center;
  margin-bottom: toRem(56);
}

.pwMention {
  color: $orange500;
}

.link {
  margin-top: toRem(54);
}

@include respond(mobile) {
  .section {
    padding: 0;
  }

  .title {
    font-size: toRem(27);
    margin-bottom: toRem(28);
  }
}
