@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.feature {
  width: toRem(320);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.fullWidth {
    width: 100%;
    justify-content: center;
  }

  &Image {
    display: flex;
    height: 81px;
    justify-content: center;
    align-items: center;
  }

  &Title {
    padding-top: 30px;
    font-size: toRem(24);
  }

  &Description {
    max-width: toRem(205);
    text-align: center;
    font-size: toRem(18);
    font-weight: normal;
    margin-top: toRem(20);
    margin-bottom: toRem(20);
    color: $grey500;
  }
}
